jQuery(document).foundation();
/*
These functions make sure WordPress
and Foundation play nice together.
*/
jQuery(document).ready(function($) {

  /* Make all External Links and PDF's open in a new tab */
    var host = new RegExp('/' + window.location.host + '/');
    $('a').each(function() {
       if (host.test(this.href) && (this.href).indexOf("#") != -1 && (this.href).length > 2 && (this.href).indexOf('.pdf') < 0 && !$(this.children).hasClass("cff-more")){
          // This is an internal link, remove target blank from URL
          $(this).removeAttr('target');
       }
    });
  /* End External Link */

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();
	// Makes sure last grid item floats left
	jQuery('.archive-grid .columns').last().addClass( 'end' );
	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

  // START: Mobile Menu
  jQuery('#menu-primary-menu').slicknav({
		label: 'MENU',
		duplicate: true,
		duration: 300,
		easingOpen: 'swing',
		easingClose: 'swing',
		closedSymbol: '<span class="icon-circle-right"></span>',
		openedSymbol: '<span class="icon-circle-down"></span>',
		prependTo: '',
		appendTo: 'header.header',
		parentTag: 'a',
		closeOnClick: false,
		allowParentLinks: false,
		nestedParentLinks: true,
		showChildren: false,
		removeIds: true,
		removeClasses: false,
		removeStyles: false,
		brand: '',
		animations: 'jquery'
	});
	jQuery('.slicknav_btn').find('.slicknav_icon').remove();
	jQuery('.slicknav_btn').find('.slicknav_menutxt').text('');
	jQuery('.slicknav_btn').prepend('<span class="slicknav_icon"><span class="icon-menu"></span><span class="icon-close"></span></span>');
 	jQuery('.slicknav_btn').prependTo('.mobile-menu-container');
	jQuery('.slicknav_btn').on('click', function(event) {
		event.stopImmediatePropagation();
	})
	jQuery('.slicknav_menu').on('click', function(event) {
		event.stopImmediatePropagation();
	})
	jQuery(document).on("mousedown, touchstart, click",function(event){
		if(jQuery('.slicknav_btn').hasClass('slicknav_open')) {
			jQuery('#menu-primary-menu').slicknav('close');
		}
	});
  // END: Mobile Menu

  // START: Scroll To Top
  jQuery('#scrollTop').click(function() {
      jQuery('html, body').animate({
          scrollTop : 0
      }, 1000);
  });
  // END: Scroll To Top

  //Equal Heights for facebook feed
  equalheight = function(container){

  var currentTallest = 0,
       currentRowStart = 0,
       rowDivs = new Array(),
       $el,
       topPosition = 0;
   jQuery(container).each(function() {

     $el = jQuery(this);
     jQuery($el).height('auto')
     topPostion = $el.position().top;

     if (currentRowStart != topPostion) {
       for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
         rowDivs[currentDiv].height(currentTallest);
       }
       rowDivs.length = 0; // empty the array
       currentRowStart = topPostion;
       currentTallest = $el.height();
       rowDivs.push($el);
     } else {
       rowDivs.push($el);
       currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
    }
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
   });
  }

  //jQuery(window).load(function() {
    //equalheight('.social-display .cff-item');
  //});


  //jQuery(window).resize(function(){
    //equalheight('.social-display .cff-item');
  //});

  //Mobile Facility Search Filters
  jQuery(".mobile-toggle").click(function() {
   //jQuery( ".filter-container" ).animate({ height: '0'});
   jQuery( ".search-filters, .filter-container" ).toggleClass("filter-expand");
   jQuery('body').toggleClass('scrollLock');
  });

  jQuery("#close-button").click(function(){
    jQuery( ".search-filters, .filter-container" ).toggleClass("filter-expand");
    jQuery('body').toggleClass('scrollLock');
  });

  //jQuery('.mobile-toggle').click(function(event) {
    //jQuery('html').one('click',function() {
      //jQuery( ".search-filters, .filter-container" ).toggleClass("filter-expand");
    //});

    //event.stopPropagation();
  //});
  jQuery(document).keyup(function(e) {
     if (e.keyCode == 27) { // escape key maps to keycode `27`
        jQuery( ".search-filters, .filter-container" ).toggleClass("filter-expand");
      }
  });

  //Move the Facebook image above the text
  jQuery('.cff-text-wrapper').each(function(){
       jQuery(this).next('.cff-photo').after(this);
  });

  // News Toggle
  jQuery(".news-show").click(function() {
   //jQuery( ".filter-container" ).animate({ height: '0'});
   jQuery( ".social-display, .social-show" ).removeClass("active");
   jQuery( ".news-display, .news-show" ).addClass("active");
  });
  jQuery(".social-show").click(function() {
   //jQuery( ".filter-container" ).animate({ height: '0'});
   jQuery( ".news-display, .news-show" ).removeClass("active");
   jQuery( ".social-display, .social-show" ).addClass("active");
  });

  //Facebook image adjustments to normalize things more
  jQuery('#cff .cff-photo img').each(function(){
    var $self = $(this);
    if ($self.width() < $self.height()) {
        $self.addClass('portrait');
    }
  });
  jQuery('#cff video').each(function(){
    var $self = $(this);
    if ($self.width() < $self.height()) {
        $self.addClass('portrait');
    }
  });

  // ANDROID CHECK
  var ua = navigator.userAgent;
  if( ua.indexOf("iPhone") >= 0 ){
    jQuery('body').addClass('iphoneBrowser');
  }
  // GOOGLE TAG MANAGER

    $('video').on('play', function(){
     var getVideoName = $(this).children('source').attr('src').split('/').pop();
     // console.log(getVideoName)
     dataLayer.push({
       'event': 'VideoPlayed',
       'videoPlayed': getVideoName
     });
    })

  $('a[href$=jpg], a[href$=JPG], a[href$=pdf], a[href$=PDF], a[href$=doc], a[href$=DOC], a[href$=xls], a[href$=XLS], a[href$=ppt], a[href$=PPT], a[href$=pptx], a[href$=PPTX]').on("click", function(e){
    var getFileName = $(this).attr("href").split('/').pop();
    dataLayer.push({
      'event': 'FileDownloaded',
      'fileDownloaded': getFileName
    });
  });



    ///////////////////////////////////
  // Talent network Popup
  ////////////////////////////////

   function SubscribeModalSetCookie(key, value, days) {
      var expires = new Date();
      if (days) {
          expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
          document.cookie = key + '=' + value + ';expires=' + expires.toUTCString() + ';path=/';
      } else {
          // Set a session-only cookie on entire root domain (remove expires portion)
          document.cookie = key + '=' + value + ';path=/';
      }
    }

  function SubscribeModalGetCookie(key) {
      var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
      return keyValue
          ? keyValue[2]
          : null;
  }


// COMMENT OUT BELOW AUTOMATIC POP UP FOR TESTING HIDDEN FORM ON LIVE SITE.
  setTimeout(function(){
    if (SubscribeModalGetCookie('SubscribeModalCookie') != 'UserAllowed') {
		if ($('#talentNetworkModal').length){
			var popup = new Foundation.Reveal($('#talentNetworkModal'));
			popup.open();
      	//$('#talentNetworkModal').foundation('open');
			// console.log('it has been 15 seconds, and this user doesnt have a cookie, fire it yall');
		}
    } else {
        // console.log('cookie is already set, do nothing');
        // do nothing
    }
  }, 15000);//12000

    $('#talentNetworkModal').on('closed.zf.reveal', function (){
        // set session-only cookie on modal close
        SubscribeModalSetCookie('SubscribeModalCookie', 'UserAllowed');
    });
    //END Talent network Popup

	///////////////////////////////////
	// Homepage banner
	////////////////////////////////
	if (SubscribeModalGetCookie('HomepageBannerCookie') != 'UserClosed'){
		$(".message-bar").slideDown(300,function(){
			$(window).scrollTop(0);
		});
	}

	$( ".message-bar .close-button" ).click(function(){
		$(".message-bar").slideUp(300,function(){
			SubscribeModalSetCookie('HomepageBannerCookie', 'UserClosed', 30);
		});
	});
	// End Homepage Banner

  ///////////////////////////////////
  // NA Page Q&A Boxes
  ////////////////////////////////
  $( ".question-button" ).click(function() {
    $(".answer-modal").fadeOut();
    $(this).parent().siblings(".answer-modal").fadeIn();
  });

  $( ".answer-button" ).click(function() {
    $(this).parent().fadeOut();
  });

  ///////////////////////////////////
  // Blog Category Sidebar Scroll Freeze
  ////////////////////////////////
	if ($("main .sidebar").length && ($('body').hasClass('page-template-template-blog-listing') || $('body').hasClass('tax-blog-category'))){

		var menuPosX;
		var menuPosY;
		var menuWidth;
		calcSidebarPos();

		// Windows scroll listener
		$(window).scroll(function(){
			var windowPos = $(document).scrollTop();
			if (isDesktop() && windowPos >= menuPosX){
				$('body').addClass('lock-sidebar');
				$('main .sidebar nav').css('top',0);
				$('main .sidebar nav').css('left',menuPosY);
				$('main .sidebar nav').css('width',menuWidth);
			} else {
				$('body').removeClass('lock-sidebar');
				$('main .sidebar nav').removeAttr('style');
				calcSidebarPos();
			}
		});

		// Recalculates if windows is resized (Desktop only)
		$(window).resize(function(){
			if (isDesktop()){
				calcSidebarPos();
			}
		});
	}
	function calcSidebarPos(){
		menuPosX = $('main .sidebar nav').offset().top;
		menuPosY = $('main .sidebar nav').offset().left;
		menuWidth = $('main .sidebar').outerWidth();
	}
	function isDesktop(){
		if (window.matchMedia("(min-width: 1024px)").matches){return true;}
		else {return false;}
	}



});
